.legal {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 60px;
  padding-bottom: 60px;
  h1 {
    font-size: 32px;
    margin-bottom: 24px;
  }
  h2 {
    font-weight: 900;
    font-size: 24px;
    margin-bottom: 18px;
    margin-top: 24px;
  }
  h3 {
    font-weight: 900;
    font-size: 18px;
    margin-bottom: 18px;
    margin-top: 24px;
  }
  p,
  li {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  li {
    margin-left: 16px;
  }

  br {
    margin-bottom: 18px;
  }
}
