.mainPageHeader {
  min-height: 520px;
  max-height: 520px;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 75px;
  background-image: $headerImage;
  @extend %backgroundImage;

  .soon {
    color: white;
    font-size: 24px;
    font-family: $boldFont;
    padding-top: 24px;
  }

  .messageContainer .title {
    color: white !important;
  }

  .messageContainer .description {
    color: white !important;
  }

  & .logoContainer {
    display: flex;
    padding-bottom: 20px;
    justify-content: center;

    & .logo {
      height: 150px;
      width: 150px;
      background-image: $pageLogo;
      @extend %backgroundImage;
    }
  }

  & .messageContainer {
    display: flex;
    width: 45%;
    flex-direction: column;

    & .title,
    & .description {
      text-align: center;
    }

    & .title {
      color: $descriptionColor;
      margin-bottom: 20px;
    }

    & .description {
      font-size: 18px;
    }
  }

  & .onlineStores {
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    padding-top: 50px;

    & .appleStore,
    & .androidStore {
      height: 50px;
      width: 200px;
      cursor: pointer;
      @extend %backgroundImage;
      background-size: auto 100%;
    }

    & .appleStore {
      background-image: $appleStore;
    }
    & .androidStore {
      background-image: $androidStore;
    }
  }
  & .scrollDown {
    display: none;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    & .text {
      font-family: $boldFont;
      font-size: 14px;
      color: $descriptionColor;
      margin-bottom: 5px;
    }

    & .icon {
      height: 10px;
      width: 20px;
      background-image: $chevronIcon;
      @extend %backgroundImage;

      position: absolute;
      animation: bounce;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
    }
  }
}

@media only screen and (max-width: 600px) {
  .mainPageHeader {
    min-height: 95vh;
    max-height: 95vh;
    align-items: center;
    position: relative;

    padding-top: 15%;
    background-image: $headerImageMobile;
    & .logoContainer {
      padding-bottom: 30px;
    }

    & .messageContainer {
      width: 75%;
      padding-bottom: 0%;
    }

    & .onlineStores {
      &.apple {
        padding-top: 5%;
        padding-bottom: 50%;
        flex: 1;
      }
      &.android {
        padding-top: 10%;
        padding-bottom: 50%;
        flex: 1;
      }
    }

    & .scrollDown {
      display: flex;
      position: absolute;
      bottom: 7.5%;
      &.apple {
        bottom: 12.5%;
      }
    }
  }
}

@keyframes bounce {
  0% {
    top: 20px;
  }
  50% {
    top: 30px;
  }
  100% {
    top: 20px;
  }
}
